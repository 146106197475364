import {jsPDF} from "jspdf";
let jsPdfExportTajawal = (orientation = "p", unit = "px", format = "a4" || []) => {
    let pdf = jsPDF(orientation, unit, format);
    // const _fornt = pdf.loadFile("https://alfont.com/wp-content/fonts/thulth-arabic-fonts//alfont_com_IBMPlexSansArabic-Light.ttf", true);
    const _fornt = pdf.loadFile("https://alfont.com/wp-content/fonts/new-arabic-fonts//alfont_com_AlFont_com_XB-Yas-1.ttf", true);
    pdf.addFileToVFS("alfont_com_AlFont_com_XB-Yas-1.ttf", _fornt);

    pdf.addFont("alfont_com_AlFont_com_XB-Yas-1.ttf", "AlHurra", "normal");

    return pdf;
}
export default jsPdfExportTajawal;