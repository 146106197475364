<template>
  <div>
    <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '65px 40px','background-color': '#ffffff' }">

      <div>
        <div class="d-flex justify-content-between">
          <div>
            <template v-if="status == 'simplified'">
              <p class="title-1 space-word-15">فــاتورة ضريبية مبسطة</p>
              <p class="title-2">Simplified tax invoice</p>
            </template>
            <template v-else>
              <p class="title-1 space-word-15">فاتورة ضريبية</p>
              <p class="title-2">Tax Invoice</p>
            </template>

          </div>
          <div class="d-flex">
            <div>
              <img :src="rel_logo" class="logo-w" alt="">
            </div>

          </div>
        </div>
        <div class="d-flex mt-10">
          <div class="mr-2">
            <div class="green-cc">
              <h5 class="color-1a5a4a mb-1 f-s-20px"><b>الرقم التسلسلي</b></h5>
              <p class="mb-0 space-latter-2">{{ data.invoice_code }}</p>
            </div>
          </div>
          <div class="ml-2 mr-2">
            <div class="green-cc">
              <h5 class="color-1a5a4a mb-1 f-s-20px"><b>تاريخ الفاتـورة</b></h5>
              <p class="mb-0 space-latter-2">{{ data.invoice_date }}</p>
            </div>
          </div>
          <div class="ml-2 mr-2">
            <div class="green-cc">
              <h5 class="color-1a5a4a mb-1 f-s-20px"><b>تاريخ الإستحقاق</b></h5>
              <p class="mb-0 space-latter-2">{{ data.issue_date }}</p>
            </div>
          </div>
        </div>
        <div class="mt-3 d-flex">
          <div class="w-60per">
            <div class="green-cc-3">
              <h5 class="color-1a5a4a f-s-22px mb-4"><b>معلومات الـبـــــائع</b></h5>
              <div class="d-flex">
                <span class="par-h"></span>
                <div>
                  <p class="f-s-19px mb-1">{{ data.company ? data.company.business_name : '' }}</p>
                  <p class="f-s-19px mb-0">{{ data.company ? (data.company.country_name) + (data.company.city_name ? ' - ' + data.company.city_name : '') + (data.company.address_1 ? ' - ' + data.company.address_1 : '') : '' }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-2 mr-2 w-40per" v-if="data.company && (data.company.business_record || data.company.tax_no)">
            <div class="green-cc-2">
              <h5 class="f-s-22px mb-0" v-if="data.company.business_record">
                <p class="color-1a5a4a mb-1">رقم السجل التجــــاري</p>
                <p class="mb-0 f-s-19px">{{ data.company ? data.company.business_record : '' }}</p>
              </h5>
              <h5 class="f-s-22px mb-0" v-if="data.company.tax_no">
                <p class="color-1a5a4a mb-1">رقم التسجيل الضريبة</p>
                <p class="mb-0 f-s-19px">{{ data.company ? data.company.tax_no : '' }}</p>
              </h5>
            </div>
          </div>
        </div>
        <div class="mt-2 d-flex">
          <div class="w-60per">
            <div class="green-cc-3">
              <h5 class="color-1a5a4a f-s-22px mb-4"><b>معلومات المشتري</b></h5>
              <div class="d-flex">
                <span class="par-h"></span>
                <div>
                  <p class="f-s-19px mb-1">{{ data.customer ? data.customer.fullname : '' }}</p>
                  <p class="f-s-19px mb-0">{{ data.customer ? (data.customer.country_name) + (data.customer.city_name ? ' - ' + data.customer.city_name : '') + (data.customer.address_1 ? ' - ' + data.customer.address_1 : '') : '' }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-2 mr-2 w-40per" v-if="data.customer && (data.customer.commercial_register || data.customer.tax_register)">
            <div class="green-cc-2">
              <h5 class="f-s-22px mb-0" v-if="data.customer.commercial_register">
                <p class="color-1a5a4a mb-1">رقم السجل التجــــاري</p>
                <p class="mb-0 f-s-19px">{{ data.customer ? data.customer.commercial_register : '' }}</p>
              </h5>
              <h5 class="f-s-22px mb-0" v-if="data.customer.tax_register">
                <p class="color-1a5a4a mb-1">رقم التسجيل الضريبة</p>
                <p class="mb-0 f-s-19px">{{ data.customer ? data.customer.tax_register : '' }}</p>
              </h5>
            </div>
          </div>
        </div>
        <div class="mt-5 table-border-radius">
          <table class="table" style="margin: 0;text-align: center;">
            <thead>
            <tr style="background:#ffffff">
              <!--              <th style="width: 248px;">{{ $t('invoice_sales_print.item') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.price') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.quantity') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.total') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.discount') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.tax_rate') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.tax_value') }}</th>-->
              <!--              <th style="width: 152px;">{{ $t('invoice_sales_print.the_total_includes_VAT') }}</th>-->
              <th style="width: 179px;">المنتج</th>
              <th>ســعر الوحدة</th>
              <th>الكمية</th>
              <th>خصومات</th>
              <th style="width: 152px;">المجموع الفرعي بـدون الـضـريـبـة</th>
              <th>نــسـبـة الضريبة</th>
              <th>قــيــمـة الضريبة</th>
              <th style="width: 152px;">المجموع شامل ضريبة القيمة المضافة</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in items_list" :key="index">
              <td style="overflow: hidden;"><span style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;">{{ row.item ? row.item.name : '' }} - {{ row.service_name ? row.service_name:'' }}</span></td>
              <td>{{ row.service_price }}</td>
              <td>{{ row.qty }}</td>
              <td>{{ row.discount_calc ? row.discount_calc : '0' }}</td>
              <td>{{ row.subtotal_before_tax ? row.subtotal_before_tax : '0' }}</td>
              <td>{{ row.tax_percentage ? row.tax_percentage : '0' }}</td>
              <td>{{ row.amount_tax ? row.amount_tax : '0' }}</td>
              <td>{{ row.subtotal }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
      <div class="d-flex justify-content-between">
        <div class="w-330">
          <div class="total-border d-flex mt-3 justify-content-between">
            <div class="padding-5-20"><h3 class="mb-0 rtl-dir color-1a5a4a">المجموع</h3></div>
            <div class="padding-5-20 d-flex"><span class="sum-sum"><b class="f-s-22px">{{ data.invoice_total_before_taxes }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>
          <div class="total-border d-flex mt-3 justify-content-between">
            <div class="padding-5-20"><h3 class="mb-0 rtl-dir color-1a5a4a">مجموع الخصومات</h3></div>
            <div class="padding-5-20 d-flex"><span class="sum-sum"><b class="f-s-22px">{{ data.invoice_total_discount }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>

          <div class="total-border d-flex mt-3 justify-content-between">
            <div class="padding-5-20"><h3 class="mb-0 rtl-dir color-1a5a4a">ضريبة القيمة المضافة )15%(</h3></div>
            <div class="padding-5-20 d-flex"><span class="sum-sum"><b class="f-s-22px">{{ data.invoice_total_taxes }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>

          <div class="total-border d-flex mt-3 justify-content-between">
            <div class="padding-5-20"><h3 class="mb-0 rtl-dir color-1a5a4a">المجموع مع الضريبة )15%(</h3></div>
            <div class="padding-5-20 d-flex"><span class="sum-sum"><b class="f-s-22px">{{ data.invoice_total_without_shipping }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>

        </div>
        <div class="text-center">
          <div class="qr-border text-center" style="margin: 10px auto 3px auto; width: 152px;">
            <qr-code v-if="data.data_qr_code" :text="data.data_qr_code" :size="size"></qr-code>
          </div>
          <p class="fs-12 mb-0 h-16px word-spacing-4px">رمز الإستجابة مشفر بحسب متطلبات هيئة</p>
          <p class="fs-12 mb-0 h-16px word-spacing-4px">الزكاة والضريبة والجمارك للفوترة الإلكترونية</p>
          <p class="fs-12 mb-0 h-16px">This QR code is encoded as per</p>
          <p class="fs-12 mb-0 h-16px">ZATCA e-invoicing requirements</p>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.service";

import VueQRCodeComponent from 'vue-qrcode-component';
import jsPdfExportTajawal from "@/core/config/jsPdfExportTajawal";

export default {
  name: "print-sales-tax-invoice",
  components: {
    'qr-code': VueQRCodeComponent
  },

  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'sales/sales_laundries',

      data: {},
      items_list: [],
      idEditing: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
      status: this.$route.params.status ? this.$route.params.status : null,
      items_total: 0,
      total: 0,
      show: false,
      is_show_price: (this.$route.query.is_show_price == 0) ? false : true,
      type: this.$route.params.type ? this.$route.params.type : null,
      size: 140,
    }
  },
  computed: {
    rel_logo: function () {
      if(this.data && this.data.company && this.data.company.logo_url){
        return this.data.company.logo_url;
      }else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
    qr_data: function () {
      let _q_q = '';
      if (this.data.company && this.data.company.name) {
        _q_q += this.data.company.name;
      }
      if (this.data.company && this.data.company.tax_no) {
        _q_q += ' ' + this.data.company.tax_no;
      }
      if (this.data.invoice_date) {
        _q_q += ' ' + this.data.invoice_date;
      }
      if (this.data.invoice_total_taxes) {
        _q_q += ' ' + this.data.invoice_total_taxes;
      }
      if (this.data.invoice_total) {
        _q_q += ' ' + this.data.invoice_total;
      }
      return _q_q;
    },
  },
  watch: {
    items_list: {
      handler(val) {
        if (val.length > 0) {
          this.items_total = this.$_.sumBy(this.items_list, 'subtotal');
          this.items_total = this.items_total ? this.items_total : 0;
          this.total = this.items_total;

        }
      },
      deep: true
    }
  },
  methods: {
    printInvoice() {
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      let pdf = jsPdfExportTajawal("p", "px", 'a4');

      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }
        }
      });
    },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        this.show = true;
      });
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printInvoice();
    });
    // window.print();
  }
}
</script>

<style>
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.title-1 {
  font-size: 39px;
  color: #1a5a4a;
}

.title-2 {
  font-size: 30px;
  color: #8898b2;
  padding-top: 8px;
}

.logo-w {
  width: 162px;
  margin-left: 77px;
}

.color-1a5a4a {
  color: #1a5a4a;
}

.green-cc {
  border: 2px solid #1a5a4a;
  padding: 2px 15px;
  border-radius: 10px;
  text-align: center;
}

.green-cc-3 {
  border: 2px solid #1a5a4a;
  padding: 10px 15px;
  border-radius: 10px;
  height: 100%;
}

.green-cc-2 {
  border: 2px solid #1a5a4a;
  padding: 6px 15px;
  border-radius: 10px;
}

.f-s-22px {
  font-size: 22px;
}

.f-s-19px {
  font-size: 19px;
}

.h-16px {
  height: 16px !important;
}

.f-s-20px {
  font-size: 20px;
  font-weight: 400;
}

.w-60per {
  width: 100%;
  display: inline-block;
}

.w-40per {
  width: 31%;
  display: inline-block;
}

.padding-25-15 {
  padding: 21px 15px !important;
}

.padding-b-15 {
  margin-bottom: 15px;
}

.fs-12 {
  font-size: 12px !important;
}
.word-spacing-4px{
  word-spacing: 4px;
}
.par-h {
  width: 1.5px;
  margin-left: 10px;
  background-color: #8898b2;
  display: block;
}

.qr-border {
  border: 2px solid #1a5a4a !important;
  padding: 5px;
  border-radius: 5px;
}

.total-border {
  border: 2px solid #1a5a4a !important;
  border-radius: 5px;
}

.w-330 {
  width: 55%;
}

.padding-5-20 {
  padding: 5px 20px !important;
}

.border-r-2 {
  border-right: 2px solid !important;
}

.rtl-dir {
  direction: rtl !important;
  float: right !important;
  display: block !important;
}

#invoice {
  font-family: "AlHurra";
  width: 235mm;
  font-weight: 400 !important;
}

/*.font-family-Tajawal {*/
/*  font-family: "Roboto", sans-serif !important;*/
/*  word-spacing: initial !important;*/
/*}*/
/*#invoice * {*/
/*  word-spacing: 1px !important;*/
/*}*/

/*#invoice .space-word-2{*/
/*  word-spacing: 10px !important;*/
/*}*/
/*#invoice .space-word-15{*/
/*  word-spacing: 26px !important;*/
/*}*/
/*#invoice .space-latter-2{*/
/*  letter-spacing: 1px !important;*/
/*}*/
/*table, thead, tr, th, tbody, td {*/
/*  border: 2px solid #1a5a4a !important;*/
/*}*/

table thead tr th{
  border-bottom: 2px solid #1a5a4a !important;
}
table thead tr th:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}
table tbody tr td:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}
.table-border-radius {
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #1a5a4a !important;
}

tbody td, tbody tr {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.invoice-box {
  background-color: #fff;

  margin: auto;
  padding: 30px;
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(3) {
  text-align: right;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}
.sum-sum{
  text-align: center;
  margin-left: 15px;
  min-width: 60px;
}
.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.table thead th {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #1a5a4a;
  vertical-align: middle;
}
</style>